<template>
  <div>
    <h2 class="content-block">Profil</h2>

    <div class="content-block dx-card responsive-paddings">
      <dx-form
        id="form"
        label-location="top"
        :form-data="formData"
        :colCountByScreen="colCountByScreen"
      />
    </div>
  </div>
</template>

<script>
import DxForm from "devextreme-vue/form";
import auth from "../auth";

export default {
  props: {
    picture: String
  },

  data() {

    const formData = {};

    return {
      formData,
    }
  },

  methods: {
    async getUserData(){
      await auth.getUser().then((e) => {
        this.formData = e.data;
      });
    },
  },

  async mounted(){
    await this.getUserData();
  }, 

  setup() {


    const colCountByScreen = {
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4
    }

    return {
      colCountByScreen
    };
  },
  components: {
    DxForm
  }
};
</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>
