import {useApplicationStore} from "@/stores/ApplicationStore";
import {defineStore} from "pinia";
import axios from "axios"
import auth from "@/auth"
import {useUserStore} from "@/stores/UserStore";

export const useSupplierStore = defineStore('SupplierStore', {

    state: () => {

        const supplierData: any[] = [];

        return {
            supplierData
        };
    },
    actions: {

        async getSuppliers() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axios.get(applicationStore.getApiUrl + 'supplier', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("fetched supplier data..");
                this.supplierData = data.data;

            } catch (error: any) {
                throw error;
            }
        },

        async updateSingleSupplier(idToUpdate: any, elementToUpdate: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axios.put(applicationStore.getApiUrl + 'supplier/' + idToUpdate, elementToUpdate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                throw error.response.status + " http error";
            }
        },

        async createSingleSupplier(elementToCreate: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axios.post(applicationStore.getApiUrl + 'supplier', elementToCreate, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                throw error.response.status + " http error";
            }
        },
        async deleteSingleSupplier(idToDelete: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axios.delete(applicationStore.getApiUrl + 'supplier/' + idToDelete, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            } catch (error: any) {
                throw error.response.status + " http error";
            }
        },
    },
    getters: {},
    persist: true,
});