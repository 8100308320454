import {useApplicationStore} from "@/stores/ApplicationStore";
import {defineStore} from "pinia";
import axios from "axios"
import {useUserStore} from "@/stores/UserStore";

export const useFinishedGoodsConsumptionStore = defineStore('FinishedGoodsConsumptionStore', {

    state: () => {

        const finishedGoodsConsumptionData: any[] = [];
        const finishedGoodsConsumptionComparisonData: any[] = [];

        const finishedGoodsConsumptionTranslations = {
            net_weight_cotton_conventional: "Cotton Conventional",
            net_weight_cotton_fairtrade_ocs: "Cotton Fairtrade OCS",
            net_weight_cotton_fairtrade_gots: "Cotton Fairtrade GOTS",
            net_weight_cotton_gots: "Cotton GOTS",
            net_weight_cotton_cmia: "Cotton CMIA",
            net_weight_polyester_conventional: "Polyester Conventional",
            net_weight_polyester_rcs: "Polyester RCS",
            net_weight_polyester_grs: "Polyester GRS",
            net_weight_tencel: "Tencel",
            net_weight_refibra: "Refibra",
        }

        return {
            finishedGoodsConsumptionTranslations,
            finishedGoodsConsumptionData,
            finishedGoodsConsumptionComparisonData,
        };
    },
    actions: {

        async getComparisonConsumptionData(year: any, language: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axios.get(applicationStore.getApiUrl + 'finishedGoodsConsumption/comparison/year/' + year + `/${language}`, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("fetched finishedGoodsConsumption comparison data..");
                this.finishedGoodsConsumptionComparisonData = data.data;

            } catch (error: any) {
                throw error;
            }
        },

        async getFinishedGoodsConsumptionsByYear(year: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axios.get(applicationStore.getApiUrl + 'finishedGoodsConsumption/year/' + year, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                console.log("fetched finishedGoodsConsumption data..");
                this.finishedGoodsConsumptionData = data.data;

            } catch (error: any) {
                throw error;
            }
        },
    },
    getters: {
        summedValues: (state: any) => {

            const finishedGoodsConsumptionStore = useFinishedGoodsConsumptionStore();

            const summedValues: any[] = [];


            return summedValues;


        }
    },
    persist: true,
});