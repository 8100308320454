export default [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    authRole: 0,
  },
  {
    text: "Administration",
    icon: "preferences",
    authRole: 2,
    items: [
      {
        text: "User Configuration",
        path: "/user-configuration",
        authRole: 2,
      },
      {
        text: "Supplier Configuration",
        path: "/supplier-configuration",
        authRole: 2,
      },
      {
        text: "Finished Goods Consu...",
        path: "/summary",
        authRole: 2,
      },
    ]
  },
  {
    text: "Data Management",
    icon: "file",
    authRole: 1,
    items: [
      {
        text: "Delivery data",
        path: "/delivery-data",
        role: 1,
      },
    ]
  },
  {
    text: "Profile",
    icon: "card",
    authRole: 0,
    items: [
      {
        text: "Logout",
        path: "/logout",
        authRole: 0,
      }
    ]
  }
];
