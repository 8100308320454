<template>
  <div>
    <h2 class="content-block">Supplier configuration</h2>

    <DxDataGrid
      class="dx-card wide-card"
      ref="orderDataGridComponent"
      :data-source="orderGridDataSource"
      :show-borders="true"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :allowColumnReordering="false"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
    >
      <DxFilterRow :visible="false" />
      <DxHeaderFilter :visible="false" />

      <DxEditing
        mode="batch"
        :allow-adding="true"
        :allow-deleting="false"
        :allow-updating="true"
      />

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="false" />

      <dx-column
        dataField="id"
        caption="id"
        data-type="string"
        :allow-editing="false"
      />

      <dx-column
        data-field="name"
        caption="name"
        data-type="string"
        :allow-editing="true"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxHeaderFilter,
  DxEditing,
} from "devextreme-vue/data-grid";
import {useSupplierStore} from "@/stores/SupplierStore";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  setup(){

    const supplierStore = useSupplierStore();

    return {supplierStore};
  },

  async mounted() {
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "id",
      load: async () => {
        await this.supplierStore.getSuppliers();
        return this.supplierStore.supplierData;
      },

      update: async (key, values) => {
        await this.supplierStore.updateSingleSupplier(key, values)
      },

      insert: async (values) => {
        await this.supplierStore.createSingleSupplier(values);
      },

      remove: async (key) => {
        await this.supplierStore.deleteSingleSupplier(key);
      },
    });


    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
    };
  },
  methods: {

    /**
     * Bestellen & Warenkorb
     */

    /**
     * Value Change Events
     */

  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
  },
};
</script>

<style>

</style>
