<template #default>
  <div class="dx-swatch-additional side-navigation-menu" @click="forwardClick">
    <slot/>
    <div class="menu-container">
      <dx-tree-view
          ref="treeViewRef"
          :items="items"
          key-expr="path"
          selection-mode="single"
          :focus-state-enabled="false"
          expand-event="click"
          @item-click="handleItemClick"
          width="100%"
      />
      <div class="logo-container"></div>
    </div>
  </div>
</template>

<script>
import DxTreeView from "devextreme-vue/ui/tree-view";
import {sizes} from "@/utils/media-query";
import navigation from "../app-navigation";
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import auth from "../auth";
import {useUserStore} from "@/stores/UserStore";

export default {
  props: {
    compactMode: Boolean,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();

    const userStore = useUserStore();
    const isLargeScreen = sizes()["screen-large"];

    const items = navigation.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }

      //check if menu items should be displayed
      for (const [key, value] of Object.entries(
          userStore.user
      )) {

        if (item) {
          if(key === 'role'){
            const authRoleId = Number(item.authRole);
            if (authRoleId > value){
              item.visible = false;
            }
          }
        }
      }

      // Check if menu child items should be displayed
      if (item.items != null) {
        for (let i = 0; i < item.items.length; i++) {
          item.items[i].visible = true;
          if (item.items[i].authRoleId != null) {
            for (const [key, value] of Object.entries(
             //   companyStore.company[0]
            )) {
              if (item.items[i].authVariable === key) {
                if (value !== 1) {
                  item.items[i].visible = false;
                }
              }
            }
          }
        }
      }

      if(item.items) {
        if (item.items.length > 0) {
          let isAnyItemVisible = false;
          for (let z = 0; z < item.items.length; z++) {
            if (item.items[z].visible === true) {
              isAnyItemVisible = true;
            }
          }
          if (!isAnyItemVisible) {
            item.visible = false;
          }
        }
      }


      return {...item, expanded: isLargeScreen, userStore};
    });

    const treeViewRef = ref(null);

    function forwardClick(...args) {
      context.emit("click", args);
    }

    function handleItemClick(e) {
      if (e.itemData.path === "/logout") {
        auth.logOut();
        router.push({
          path: "/login-form",
          query: {redirect: route.path},
        });
      }

      if (!e.itemData.path || props.compactMode) {
        return;
      }
      router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection() {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }

      treeViewRef.value.instance.selectItem(route.path);
      treeViewRef.value.instance.expandItem(route.path);
    }

    onMounted(() => {
      updateSelection();
      if (props.compactMode) {
        treeViewRef.value.instance.collapseAll();
      }
    });

    watch(
        () => route.path,
        () => {
          updateSelection();
        }
    );

    watch(
        () => props.compactMode,
        () => {
          if (props.compactMode) {
            treeViewRef.value.instance.collapseAll();
          } else {
            updateSelection();
          }
        }
    );

    return {
      treeViewRef,
      items,
      forwardClick,
      handleItemClick,
      updateSelection,
    };
  },

  mounted() {
    watch(
        () => this.compactMode,
        () => {
          if (this.compactMode) {
            this.imageBool = true;
          } else {
            this.imageBool = false;
          }
        }
    );
  },

  data() {
    var imageBool = false;

    return {
      imageBool,
    };
  },

  components: {
    DxTreeView,
  },
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.hide {
  display: none !important;
}

.logo-container {
  padding: 15px;
}

.logo {
  width: 100% !important;
}

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }

      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }

      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: lighten($base-bg, 4);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
      .dx-treeview-node.dx-state-selected.dx-state-focused
      > .dx-treeview-item
      * {
        color: inherit;
      }
    }

    // ##
  }
}
</style>
