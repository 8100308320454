<template>
  <div>
    <h2 class="content-block">User configuration</h2>

    <DxDataGrid
        class="dx-card wide-card"
        ref="supplierDataGrid"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
    >
      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>

      <DxEditing
          mode="batch"
          :allow-adding="true"
          :allow-deleting="false"
          :allow-updating="true"
      />

      <dx-paging :page-size="10"/>
      <dx-pager :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="false"/>

      <dx-column
          dataField="id"
          caption="id"
          data-type="string"
          :allow-editing="false"
      />

      <dx-column
          data-field="name"
          caption="name"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="email"
          caption="email"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="role"
          caption="role"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          data-field="password"
          caption="password"
          data-type="string"
          :allow-editing="true"
      />

      <dx-column
          caption="supplier"
          :allow-editing="true"
          cell-template="supplierDropDown"
          width="400"
      />

      <template #supplierDropDown="props">

        <DxTagBox
            :data-source="supplierStore.supplierData"
            display-expr="name"
            value-expr="id"
            :value="getSupplierByDataRow(props)"
            :show-selection-controls="true"
            :showClearButton="true"
            :showDropDownButton="true"
            :showSelectionControls="true"
            @ValueChanged="handleSelectSupplierChanged(props, $event)"
        >

        </DxTagBox>
      </template>


    </DxDataGrid>

  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxHeaderFilter,
  DxEditing,
} from "devextreme-vue/data-grid";
import {useUserStore} from "@/stores/UserStore";
import {useSupplierStore} from "@/stores/SupplierStore";
import {DxTagBox} from "devextreme-vue";

/**
 * Reference Keys für die UI Komponenten
 */

const supplierDataGrid = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
    DxTagBox
  },

  setup() {

    const userStore = useUserStore();
    const supplierStore = useSupplierStore();

    return {userStore, supplierStore};
  },

  async mounted() {
    await this.userStore.getUsers();
    await this.supplierStore.getSuppliers();
  },

  methods: {

    getSupplierByDataRow(props){
      let rowSupplier = [];
      const user_supplier_mappings = props.data.data.user_supplier_mappings;

      for(let i = 0; i < user_supplier_mappings.length; i++){
        rowSupplier[i] = user_supplier_mappings[i].supplier.id
      }

      return rowSupplier;

    },

    async handleSelectSupplierChanged(props ,e){

      const userData = props.data.data;

      await this.userStore.updateUserSupplierMapping(userData.id, e.value)
      await this.supplierDataGridInstance.refresh();

    },
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "id",
      load: async () => {
        console.log("loading")
        await this.userStore.getUsers();
        return this.userStore.userData;
      },

      update: async (key, values) => {
        console.log("updating")
        await this.userStore.updateSingleUser(key, values)
        await this.supplierDataGridInstance.refresh();
      },

      insert: async (values) => {
        console.log("inserting")
        await this.userStore.createSingleUser(values);
        await this.supplierDataGridInstance.refresh();
      },

      remove: async (key) => {
        console.log("removing")
        await this.userStore.deleteSingleUser(key);
        await this.supplierDataGridInstance.refresh();
      },
    });

    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      supplierDataGrid,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
    };
  },
  computed: {
    supplierDataGridInstance: function () {
      return this.$refs.supplierDataGrid.instance;
    },

  },
};
</script>

<style>

</style>
