<template>
  <div>
    <h2 class="content-block">{{text[language].tableTitle}}</h2>

    <DxDataGrid
        class="dx-card wide-card"
        ref="yearComparisonDataGridComponent"
        :data-source="yearComparisonDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="false"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @exporting="onExporting">
    >

      <DxExport :enabled="true"/>
      <DxFilterRow :visible="true"/>
      <DxScrolling column-rendering-mode="virtual"/>
      <DxHeaderFilter :visible="true"/>

      <dx-paging :page-size="10"/>
      <dx-pager :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="false"/>

      <dx-column
          data-field="menge"
          caption="Menge"
          data-type="string"
      />

      <dx-column
          data-field="jahr3"
          :caption="this.currentYear - 2"
          data-type="string"
      />

      <dx-column
          data-field="jahr2"
          :caption="this.currentYear - 1"
          data-type="string"
      />

      <dx-column
          data-field="jahr1"
          :caption="this.currentYear"
          data-type="string"
      />

    </DxDataGrid>
  </div>


</template>

<script>
import DataSource from "devextreme/data/data_source";
import DxDataGrid, {
  DxColumn,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxScrolling,
} from "devextreme-vue/data-grid";
import {useSupplierDataStore} from "@/stores/SupplierDataStore";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {useFinishedGoodsConsumptionStore} from "@/stores/finishedGoodsConsumptionStore";
import {saveAs} from "file-saver";

/**
 * Reference Keys für die UI Komponenten
 */

const yearComparisonDataGridComponent = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxExport, DxScrolling,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
  },

  setup() {

    const finishedGoodsConsumptionStore = useFinishedGoodsConsumptionStore();
    const supplierDataStore = useSupplierDataStore();

    return {finishedGoodsConsumptionStore, supplierDataStore}
  },


  async mounted() {
  },

  watch: {
    async currentYear() {
      await this.orderDataGridInstance.refresh();
    },
    async language() {
      await this.orderDataGridInstance.refresh();
    }
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const text = {
      en: {
        tableTitle: "Annual comparison in tons"
      },

      de: {
        tableTitle: "Jahresvergleich in Tonnen"
      }
    }

    let currentSelectedYear = this.supplierDataStore.availableYears[0]

    const yearComparisonDataSource = new DataSource({
      load: async () => {
        await this.finishedGoodsConsumptionStore.getComparisonConsumptionData(this.currentYear, this.language);
        return this.finishedGoodsConsumptionStore.finishedGoodsConsumptionComparisonData;
      },
    });


    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {

      text,

      currentSelectedYear,

      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      yearComparisonDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      yearComparisonDataGridComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,
    };
  },

  props: ["currentYear", "language"],

  methods: {


    translateMenge(x){
      console.log(x);

      console.log("hey");

      return "test123";


    },

    formatWeightToTons(kg) {
      // Überprüfe, ob kg eine gültige Zahl ist
      if (typeof kg !== 'number' || isNaN(kg)) {
        kg = 0;
      }

      // Umrechnung von Kilogramm in Tonnen
      let tons = kg / 1000;

      // Runden auf die nächste volle Tonne
      tons = Math.ceil(tons);

      // Rückgabe der gerundeten Tonnenzahl
      return tons;
    },

    numberWithCommas(x) {

      // Wenn der Eingabewert ein String ist, konvertiere ihn in eine Zahl
      if (typeof x === 'string') {
        x = parseFloat(x);
      }

      // Konvertiere Gewicht in Tonnen und runde auf
      let roundedTons = this.formatWeightToTons(x);

      // Konvertiere die Zahl in einen String, um die Formatierung anzuwenden
      let roundedNumber = roundedTons.toString();

      // Füge Punkte für den ganzen Teil hinzu
      // Rückgabe der formatierten Zahl
      return roundedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          options.excelCell.font = {name: 'Arial', size: 12};
          options.excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              // eslint-disable-next-line no-undef
              saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'DataGrid.xlsx');
            });
      });
    },

    async selectBoxTourValueChangedEvent(item) {
      this.currentSelectedYear = item.value;
      await this.orderDataGridInstance.refresh();
    }

    /**
     * Get User Data
     */

    /**
     * Filter Grid Functions
     * */


    /**
     * Zell Funktionen der Grid Spalten
     */


  },
  computed: {

    isSupplierSelectBoxReadOnly() {
      if (this.userStore.getSupplierByUser.length <= 1) {
        return true;
      } else {
        return false;
      }
    },

    orderDataGridInstance: function () {
      return this.$refs.yearComparisonDataGridComponent.instance;
    },
    yearSelectBox: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    supplierSelectBox: function () {
      return this.$refs.chooseSupplierSelectBox.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}


.dx-adaptive-item-text {
  background-color: #EAEAEA;
}
</style>
