import auth from "./auth";
import {createRouter, createWebHashHistory, NavigationGuardNext, RouteLocationNormalized} from "vue-router";

import Home from "./views/home-page.vue";
import OrderPage from './views/supplierDeliveryData-page.vue';
import Profile from "./views/profile-page.vue";
import Tasks from "./views/finishedGoodsConsumption-page.vue";
import UserConfiguration from "./views/admin/user-page.vue";
import SupplierConfiguration from "./views/admin/supplier-page.vue";
import Order from "./views/supplierDeliveryData-page.vue";
import defaultLayout from "./layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "./layouts/single-card.vue";

function loadView(view: any) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

// @ts-ignore
const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Profile
    },
    {
      path: "/summary",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Tasks
    },
    {
      path: "/user-configuration",
      name: "user-configuration",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UserConfiguration
    },
    {
      path: "/supplier-configuration",
      name: "supplier-configuration",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SupplierConfiguration
    },
    {
      path: "/Bestellen",
      name: "order",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Order
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Login"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Passwort zurücksetzen",
        description: "Bitte gebe deine E-Mail Adresse ein, und wir senden Ihnen einen Link zum zurücksetzen des Passwortes zu."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, 
    {
      path: "/delivery-data",
      name: "order-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OrderPage
    }
  ],
  history: createWebHashHistory()
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
