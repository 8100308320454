import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import axios from 'axios';
import router from './router'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// @ts-ignore
createApp(App).use(vuetify).use(pinia, axios).use(Toast).use(router).mount('#app')


